<template>
  <div class="home">
    <!-- swiper -->
    <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
      <van-swipe-item v-for="(item,i) in banner" :key="i">
        <van-image
          :src="item.src"
        />
        <div class="create" @click="createActions()">创建活动</div>
      </van-swipe-item>
    </van-swipe>
    <!-- card -->
    <div class="cards">
      <div class="item" v-for="(item,i) in cards" :key="i">
        <div class="word">
          <p class="title">{{item.title}}</p>
          <p class="content">{{item.content}}</p>
        </div>
        <div class="img">
          <img class="imgBase" :src="item.src" alt="">
        </div>
      </div>
    </div>
    <!-- 平台功能 -->
    <div class="functions">
      <div class="top">
        <p class="title">平台功能</p>
        <p class="desc">多种投票类型，多种投票逻辑，自定义配置模板</p>
      </div>
      <div class="bottom">
        <div class="item" v-for="(item,i) in functions" :key="i">
          <div class="img">
            <img :src="item.src" alt="" class="imgBase">
          </div>
          <div class="word">
            <p class="title">{{item.title}}</p>
            <p class="content">{{item.content}}</p>
            <div class="check" @click="checkTem()">查看详情 ></div>
          </div>
        </div>
      </div>
    </div>
    <!-- 核心优势 -->
    <div class="caption">
      <div class="top">
        <p class="title">平台优势</p>
        <p class="desc">专业微信投票平台，更懂安全、更懂客户</p>
      </div>
      <div class="bottom">
        <div class="item" v-for="(item,i) in caption" :key="i">
          <div class="img">
            <img :src="item.src" alt="" class="imgBase">
          </div>
          <div class="word">{{item.title}}</div>
        </div>
      </div>
    </div>
    <!-- 使用场景 -->
    <div class="applicable">
      <div class="top">
        <p class="title">经典案例</p>
        <p class="desc">服务于数万家客户，提供多种解决方案</p>
      </div>
      <div class="bottom">
        <ul>
          <li class="item" v-for="(item,i) in category" :key="i">
            <div class="img">
              <img :src="item.src" alt="" class="imgBase">
            </div>
            <div class="word">
              <p class="title">{{item.name}}</p>
              <div class="check" @click="checkTem()">查看详情 ></div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { category } from '@/api/api.js'
export default {
  name: 'Home',
  components: {

  },
  data () {
    return {
      banner: [{
        src: require('@/assets/img/index/banner1.86df31d.png')
      },
      {
        src: require('@/assets/img/index/banner2.83b3ff5.png')
      },
      {
        src: require('@/assets/img/index/banner4.384aece.png')
      },
      {
        src: require('@/assets/img/index/banner5.b8131ca.png')
      },
      {
        src: require('@/assets/img/index/first-banner.cd92509.png')
      }
      ],
      cards: [{
        title: '安全性',
        content: '系统服务器依托阿里云强大的云计算和CDN加速等服务；自主研发加密通讯，防破解；自研加密算法，有效防止作弊',
        src: require('@/assets/img/index/performance1.9d07fa5.png')
      },
      {
        title: '稳定性',
        content: '云服务器集群+云数据库大并发架构，带宽足，能够支持上亿级别高并发的大型活动稳定举行',
        src: require('@/assets/img/index/performance2.f4b91e6.png')
      },
      {
        title: '防刷票',
        content: '数据建模投票行为，大数据计算，可有效防止各种刷票作弊行为，可拦截90%以上的水军和99%以上的机器刷票，保障活动公平公正',
        src: require('@/assets/img/index/performance3.10c16a3.png')
      }],
      functions: [{
        title: '多种投票类型',
        content: '以图片、视频等方式进行选手展示',
        src: require('@/assets/img/index/list-img1.492774b.png')
      },
      {
        title: '自定义配置模板',
        content: '功能全面，操作简单,模板丰富，强大的自定义配置',
        src: require('@/assets/img/index/list-img2.594e824.png')
      },
      {
        title: '多种场景模板选择',
        content: '投票类型多样，界面元素丰富，功能满足多种需求',
        src: require('@/assets/img/index/list-img3.4b402e0.png')
      }],
      caption: [{
        title: '数据安全保障',
        src: require('@/assets/img/index/shouye-gongnen1.png')
      },
      {
        title: '免费创建',
        src: require('@/assets/img/index/shouye-gongnen2.png')
      },
      {
        title: '极速体验',
        src: require('@/assets/img/index/shouye-gongnen3.png')
      },
      {
        title: '贴心服务',
        src: require('@/assets/img/index/shouye-gongnen4.png')
      },
      {
        title: '个性化定制',
        src: require('@/assets/img/index/shouye-gongnen5.png')
      },
      {
        title: '百万用户信赖',
        src: require('@/assets/img/index/shouye-gongnen6.png')
      }],
      category: [{
        name: '',
        src: require('@/assets/img/index/temp1.jpg')
      },
      {
        name: '',
        src: require('@/assets/img/index/temp2.jpg')
      },
      {
        name: '',
        src: require('@/assets/img/index/temp3.jpg')
      },
      {
        name: '',
        src: require('@/assets/img/index/temp4.jpg')
      },
      {
        name: '',
        src: require('@/assets/img/index/temp5.jpg')
      },
      {
        name: '',
        src: require('@/assets/img/index/temp6.jpg')
      },
      {
        name: '',
        src: require('@/assets/img/index/temp7.jpg')
      },
      {
        name: '',
        src: require('@/assets/img/index/temp8.jpg')
      },
      {
        name: '',
        src: require('@/assets/img/index/temp9.jpg')
      }]
    }
  },
  async created () {
    await this.getCategory()
  },
  methods: {
    async getCategory () {
      const res = await category()
      this.category.forEach((item, i) => {
        item.name = res.category[i].name
      })
    },
    // 检查模板
    checkTem () {
      this.$router.push({
        path: '/templetaCenter'
      })
    }
  }
}
</script>
<style lang="less" scoped>
@import "@/assets/css/com.less";
.home{
  width: 100vw;
  // 轮播图
  .my-swipe .van-swipe-item {
    width: 100vw !important;
    height: 104.5333vw;
    position: relative;
    /deep/.van-image{
      width: 100%;
      height: 100%;
      img{
        width: 100%;
        height: 100%;
        image-rendering: -moz-crisp-edges;
        image-rendering: -o-crisp-edges;
        image-rendering: -webkit-optimize-contrast;
        image-rendering: crisp-edges;
        -ms-interpolation-mode: nearest-neighbor;
      }
    }
    div.create{
      position: absolute;
      width: 26.6667vw;
      height: 5.2474vh;
      background: #fff;
      border-radius:0.8vw;
      display:flex;
      align-items: center;
      justify-content: center;
      font-size: 4vw;
    }
    &:nth-child(1){
      div.create{
        top: 40vw;
        left: 0;
        right: 0;
        bottom: none;
        margin: auto;
      }
    }
    &:nth-child(2){
      div.create{
        top: 42vw;
        left: 0;
        right: 0;
        bottom: none;
        margin: auto;
      }
    }
    &:nth-child(3){
      div.create{
        top: 46vw;
        left: 0;
        right: 0;
        bottom: none;
        margin: auto;
      }
    }
    &:nth-child(4){
      div.create{
        top: 70vw;
        left: 0;
        right: 0;
        bottom: none;
        margin: auto;
        background: #e80412;
        color: #fff;
      }
    }
    &:nth-child(5){
      div.create{
        top: 38vw;
        left: 0;
        right: 0;
        bottom: none;
        margin: auto;
      }
    }
  }
  //cards
  div.cards{
    width: 100vw;
    position: relative;
    top: -6.666667vw;
    div.item{
      width:92vw ;
      height: 23.988006vh;
      display: flex;
      background: #Fff;
      margin: auto;
      box-sizing: border-box;
      padding-top:5.333333vw;
      padding-left:5.333333vw;
      box-shadow: 0 1.333333vw 2.666667vw 0 rgb(0 0 0 / 4%);
      border-radius: 0.8vw;
      margin-bottom: 4vw;
      div.word{
        flex: 1;
        .title{
          font-size: 4vw;
        }
        .content{
          margin-top: 4vw;
          font-size: 3.466667vw;
          color: #8b94a3;
          line-height: 1.5em;
        }
      }
      div.img{
        img{
          width: 28vw;
          height: 26.933333vw;
        }
      }
    }
  }
  //functions
  div.functions{
    width: 100vw;
    box-sizing: border-box;
    padding: 6.4vw 3.2vw;
    background: #f3fafb;
    div.top{
      .title{
        font-size: 4.533333vw;
        color: #212121;
        font-weight: 500;
        text-align: center;
      }
      .desc{
        font-size: 3.466667vw;
        color: #8b94a3;
        text-align: center;
        margin: 5.333333vw 0;
        line-height: 1.5em;
      }
    }
    div.bottom{
      width: 92vw;
      margin: auto;
      div.item{
        width: 100%;
        height: 45.333333vw;
        box-shadow: 0 1.333333vw 2.666667vw 0 rgb(0 0 0 / 4%);
        border-radius: 0.8vw;
        margin-bottom: 4vw;
        display: flex;
        div.img{
          height: 100%;
          border-top-left-radius: 0.8vw;
          border-bottom-left-radius: 0.8vw;
          img{
            width: 32.933333vw;
            height: 100%;
            border-top-left-radius: 0.8vw;
            border-bottom-left-radius: 0.8vw;
          }
        }
        div.word{
          flex: 1;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          color: #fff;
          .title{
            font-size: 4vw;
          }
          .content{
            font-size: 3.466667vw;
            line-height: 1.5em;
            margin: 4vw 0;
            box-sizing: border-box;
            width: 100%;
            padding: 0 2.666666vw 0 0;
          }
          .check{
            width:26.666667vw;
            height: 8vw;
            background: #fff;
            border-radius: 0.8vw;
            color: #212121;
            box-shadow:  rgba(73, 159, 255, 0.2) 0px 1.333333vw 2.666666vw 0px;
            font-size: 3.466667vw;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
      div.item:nth-child(1){
        background: linear-gradient(-70deg,#2c59ff,#4c7fff);
      }
      div.item:nth-child(2){
        background: linear-gradient(0deg,#01a1b4,#2ad0e4);
      }
      div.item:nth-child(3){
        background: linear-gradient(0deg,#252c86,#3c4ed8 77%);
      }
    }
  }
  //caption
    div.caption{
    width: 100vw;
    box-sizing: border-box;
    padding: 6.4vw 3.2vw;
    background: #fff;
    div.top{
      .title{
        font-size: 4.533333vw;
        color: #212121;
        font-weight: 500;
        text-align: center;
      }
      .desc{
        font-size: 3.466667vw;
        color: #8b94a3;
        text-align: center;
        margin: 5.333333vw 0;
        line-height: 1.5em;
      }
    }
    div.bottom{
      width: 92vw;
      margin: auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      div.item{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 25.066667vw;
        margin-bottom: 8vw;
        div.img{
          width: 12.266667vw;
          height:17.266667vw;
          img{
            width: 100%;
            height: 100%;
          }
        }
        div.word{
          font-size: 3.733333vw;
          color: #000;
          margin-top: 1.333333vw;
        }
      }

    }
  }
  //applicable
  div.applicable{
    width: 100vw;
    box-sizing: border-box;
    padding: 6.4vw 0;
    background: url("@/assets/img/index/applicable-bg.06572be.png") no-repeat;
    background-size: 100%;
    div.top{
      .title{
        font-size: 4.533333vw;
        color: #fff;
        font-weight: 500;
        text-align: center;
      }
      .desc{
        font-size: 3.466667vw;
        color: #fff;
        text-align: center;
        margin: 5.333333vw 0;
        line-height: 1.5em;
      }
    }
    div.bottom{
      overflow: scroll;
      position: relative;
      top: 0;
      padding-bottom: 5.333333vw;
      padding-left: 5.333333vw;
      ul{
        width: 400vw;
        display: flex;
        flex-wrap: nowrap;
        li{
          width: 42.666667vw;
          margin-right: 2.666667vw;
          border-radius: 1.333333vw;
          div.img{
            background: #eefbff;
            text-align: center;
            padding-top: 2.666667vw;
            border-radius: 1.333333vw;
            img{
              width: 40vw;
              height: 72vw;
            }
          }
          div.word{
            background: #fff;
            border-radius: 1.333333vw;
            box-shadow: rgba(0, 0, 0, 0.06) 0px 1.333333vw 2.666666vw 0px;
            text-align: center;
            p.title{
              margin-top: 4vw;
              font-size: 4vw;
              color: #000;
            }
            div.check{
              font-size: 3.466667vw;
              color: @topic_color;
              padding: 2.666667vw 0;
            }
          }
        }
      }
    }
    div.bottom::-webkit-scrollbar{
      display: none;
      width: 0!important;
    }
  }
}

</style>
