import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import '@/utils/com.js'
// 重置样式
import '@/assets/css/reset.css'
import { NoticeBar, Popup, CountDown, Checkbox, CheckboxGroup, Field, Form, Dialog, Tab, Tabs, Empty, Search, List, Grid, GridItem, Skeleton, Swipe, SwipeItem, Button, Toast, Sticky, Image as VanImage, Icon, DropdownMenu, DropdownItem } from 'vant'
// 提示方法
import toast from '@/utils/toast.js'
// 可以全局使用dayjs
import dayjs from 'dayjs'
// 修改title
Vue.prototype.dayjs = dayjs
// 创建活动
Vue.prototype.createActions = function () {
  if (store.state.token) {
    if (router.currentRoute.fullPath !== '/templetaCenter') {
      router.push({
        path: '/templetaCenter'
      })
    }
  } else {
    router.push({
      path: '/login',
      query: {
        redirect: router.currentRoute.path
      }
    })
  }
}
Vue.use(toast)
Vue.use(List).use(NoticeBar).use(Popup).use(CountDown).use(CheckboxGroup).use(Checkbox).use(Field).use(Dialog).use(Form).use(Tabs).use(Tab).use(Empty).use(Search).use(GridItem).use(Grid).use(Skeleton).use(SwipeItem).use(Swipe).use(Button).use(Toast).use(Sticky).use(VanImage).use(Icon).use(DropdownMenu).use(DropdownItem)
Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
