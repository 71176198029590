<template>
  <div class="footer" v-if="footShow">
    <div class="top">
      <div class="lf">
        <img class="logo imgBase" :src="site.syslogsecond" alt="">
        <div class="tel">
          <!-- <van-icon name="phone-o" /> -->
          <span>客服热线：</span>
          <span>{{site.phone}}</span>
        </div>
        <div class="address">
          <span>地址：{{site.adress}}</span>
        </div>
      </div>
      <div class="right">
        <img class="eqcord imgBase" :src="site.imgdomin+site.qrcode" alt="">
        <div class="text">{{'客服微信'}}</div>
      </div>
    </div>
    <div class="bottom">
      <a href="https://beian.miit.gov.cn/#/home" class="beian">{{'Copyright(C)'+site.corporatename}}</a>
      <a href="https://beian.miit.gov.cn/#/home" class="beian">{{site.beian}}</a>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'Footer',
  props: {
    site: {
      required: true
    }
  },
  data () {
    return {

    }
  },
  computed: {
    ...mapState(['footShow'])
  }
}
</script>

<style lang="less" scoped>
@import "@/assets/css/com.less";
div.footer{
  background: #0b1731;
  div.top{
    padding:2.4993vh 5.3333vw;
    border-bottom: 1px solid#2b354f;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    div.lf{
      flex: 1;
      img{
        width: 23.4667vw;
        height: 2.9985vh;
      }
      div.tel,div.address{
        display: flex;
        font-size: 3.2vw;
        margin-top: 2.666667vw;
        color: #fff;
        width: 100%;
      }
      div.address{
        span{
          line-height: 1.5em;
        }
      }
    }
    div.right{
      width: 40%;
      text-align: right;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      img{
        width: 21.3333vw;
        height: 21.3333vw;
      }
      div.text{
        width: 21.3333vw;
        margin-top: 0.7496vh;
        font-size: 3.2vw;
        color: #fff;
        text-align: center;
      }
    }
  }
  div.bottom{
    font-size: 12px;
    padding: 2vw 1.5vw;
    a{
      font-size: 12px;
      color: #7a8399;
      display: block;
      text-align: center;
      margin-bottom: 1vw;
    }
  }
}
</style>
