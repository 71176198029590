import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    name: sessionStorage.getItem('name') || '网络评选平台',
    token: sessionStorage.getItem('token') || null,
    footShow: sessionStorage.getItem('footShow') || true,
    headShow: sessionStorage.getItem('headShow') || true,
    browser: sessionStorage.getItem('browser') || null,
    oSType: sessionStorage.getItem('oSType') || null
  },
  getters: {
  },
  mutations: {
    setToken (state, val) {
      state.token = val
      sessionStorage.setItem('token', val)
    },
    setFootShow (state, val) {
      state.footShow = val
      sessionStorage.setItem('footShow', val)
    },
    setHeadShow (state, val) {
      state.headShow = val
      sessionStorage.setItem('headShow', val)
    },
    setBrowser (state, val) {
      state.browser = val
      sessionStorage.setItem('browser', val)
    },
    setOSType (state, val) {
      state.oSType = val
      sessionStorage.setItem('oSType', val)
    }
  },
  actions: {
  },
  modules: {
  }
})
