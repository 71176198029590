import { post } from '@/request/request.js'
// 站点配置
export const site = params => post('/api/common/site', params)
// 模板分类
export const category = params => post('/api/template/category', params)
// 帮助分类
export const quesCategory = params => post('/api/help/category', params)
// 帮助列表
export const quesList = params => post('/api/help/list', params)
// 帮助详情
export const quesInfo = params => post('/api/help/info', params)
// 帮助首页列表
export const quesFirstList = params => post('/api/help/lists', params)
// 案例分类
export const categorycase = params => post('/api/template/categorycase', params)
// 案例中心
export const actioncase = params => post('/api/template/actioncase', params)
// 模板分类
export const temCategory = params => post('/api/template/category', params)
// 模板中心
export const temAction = params => post('/api/template/action', params)
// 发送验证码
export const sendCode = params => post('/api/sms/send', params)
// 检测验证码
export const checkCode = params => post('/api/sms/check', params)
// 会员注册
export const register = params => post('/api/user/register', params)
// 登录
export const login = params => post('/api/user/login', params)
// 重置密码
export const resetPass = params => post('/api/user/resetpwd', params)
// 退出登录
export const exit = params => post('/api/user/logout', params)
// 修改密码
export const editpass = params => post('/api/user/changepwd', params)
// 获取活动列表
export const activeList = params => post('/api/action/list', params)
// 活动详情
export const activeInfo = params => post('/api/vote/info', params)
// 创建活动
export const createAction = params => post('/api/action/actionadd', params)
// 微信扫码
export const wxlogin = params => post('/api/user/weixinlogintwo', params)
// 微信分享
export const wxShare = params => post('/api/vote/shareCode', params)
// 微信登录 获取uesrinfo
export const wxgetInfo = params => post('/api/user/userinfo', params)
