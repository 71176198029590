<template>
  <div :class="fixedStatus?'head scroll':'head'" id="head" v-show="headShow">
    <div class="item">
      <van-image
        fit="contain"
        :src="fixedStatus?logo2:logo"
      />
    </div>
    <div class="item navBtn">
      <div class="icons">
        <van-icon name="user-circle-o" />
        <van-icon name="bars" />
      </div>
      <van-dropdown-menu>
        <van-dropdown-item @open="open()" ref="item">
          <template v-for="(item,i) in userList">
            <div class="navItem" :key="i" @click="navGoto(item.path,1)">
              <div :class="item.path==$route.path?'leftIcons active':'leftIcons'">
                <van-icon name="stop" />
              </div>
              <div :class="item.path==$route.path?'active text':'text'">{{item.title}}</div>
              <div v-show="item.path==$route.path" class="rightIcons">
                <van-icon name="arrow" />
              </div>
            </div>
          </template>
        </van-dropdown-item>
        <van-dropdown-item @open="open()"  ref="item2">
          <template v-for="(item,i) in navList">
            <div class="navItem" :key="i" @click="navGoto(item.path,2)">
              <div :class="item.path==path?'leftIcons active':'leftIcons'">
                <van-icon name="stop" />
              </div>
              <div :class="item.path==path?'active text':'text'">{{item.title}}</div>
              <div v-show="item.path==path" class="rightIcons">
                <van-icon name="arrow" />
              </div>
            </div>
          </template>
        </van-dropdown-item>
      </van-dropdown-menu>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  props: {
    logo: {},
    logo2: {
    }
  },
  components: {

  },
  data () {
    return {
      fixedStatus: false,
      navList: [
        {
          title: '首页',
          path: '/'
        },
        {
          title: '产品与服务',
          path: '/produce'
        },
        {
          title: '解决方案',
          path: '/actions'
        },
        {
          title: '模板中心',
          path: '/templetaCenter'
        },
        {
          title: '活动广场',
          path: '/square'
        },
        {
          title: '帮助中心',
          path: '/help'
        },
        {
          title: '关于我们',
          path: '/aboutMe'
        }
      ],
      userList: [{
        title: '登录',
        path: '/login'
      }]
    }
  },
  watch: {
    token: {
      immediate: true,
      handler (val) {
        if (val) {
          this.userList = [
            {
              title: '活动列表',
              path: '/mylist'
            },
            {
              title: '个人中心',
              path: '/personal'
            }
          ]
        } else {
          this.userList = [{
            title: '登录',
            path: '/login'
          }]
        }
      }
    }
  },
  computed: {
    path () {
      return '/' + this.$route.path.split('/')[1]
    },
    ...mapState(['token', 'headShow'])
  },
  methods: {
    handleScroll () {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      const offsetHeight = document.querySelector('#head').offsetHeight
      if (scrollTop > offsetHeight) {
        this.fixedStatus = true
      } else {
        this.fixedStatus = false
      }
    },
    open () {
      // 先回顶部
      window.scrollTo(0, 0)
    },
    // 导航
    navGoto (path, val) {
      if (this.$route.path != path) {
        if (val == 1) {
          this.$router.push({
            path,
            query:this.$route.query
          })
        } else {
          this.$router.push(path)
        }

      }
      if (val == 1) {
        this.$refs.item.toggle(false)
      } else {
        this.$refs.item2.toggle(false)
      }
    }
  },
  mounted () {
    window.addEventListener('scroll', this.handleScroll)
  }
}
</script>

<style lang="less" scoped>
@import "@/assets/css/com.less";
div.head{
  width: 100vw;
  height: 6.8966vh;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  div.item{
    width: 50vw;
    height: 100%;
    display: flex;
    align-items: center;
    margin-left: 1.0495vh;
    position: relative;
    /deep/.van-image{
      img{
        // width: 19.4667vw;
        // height: 2.5487vh;
        width: 23.4667vw;
        height: 2.9985vh;
        image-rendering: -moz-crisp-edges;
        image-rendering: -o-crisp-edges;
        image-rendering: -webkit-optimize-contrast;
        image-rendering: crisp-edges;
        -ms-interpolation-mode: nearest-neighbor;
      }
    }
    .icons{
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 100%;
      height: 100%;
      .van-icon-bars,.van-icon-user-circle-o{
        font-size: 6.1333vw;
        margin-right: 4vw;
        color: #fff;
      }
    }
    .van-dropdown-menu{
      width: 100%;
      height: 100%;
      background: transparent;
      /deep/.van-dropdown-menu__bar{
        width: 100%;
        height: 100%;
        background: transparent;
        box-shadow: none;
        justify-content: flex-end;
        align-items: center;
        .van-dropdown-menu__item{
          width: 6.1333vw;
          height: 6.1333vw;
          margin-right: 4vw;
          flex: none;
          .van-dropdown-menu__title::after{
            display: none;
          }
        }
      }
    }
    .navItem{
      width: 96vw;
      height: 8.9955vh;
      margin: auto;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .leftIcons{
        /deep/.van-icon-stop{
          color: #f6a311;
        }
        margin-right: 1.3333vw;
        &.active{
          /deep/.van-icon-stop{
            color: @topic_color;
          }
        }
      }
      .rightIcons{
        width: 18.6667vw;
        text-align: center;
        color:  @topic_color;
        /deep/.van-icon-arrow{
          font-size: 4.2667vw;
        }
      }
      .text{
        flex: 1;
        font-size: 4vw;
        color: #333;
        &.active{
          color: @topic_color;
        }
      }

    }
  }
  &.scroll{
    background: #fff;
    /deep/.van-icon-bars,.van-icon-user-circle-o{
      color:  @topic_color !important;
    }
  }
}
</style>
