import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/Home.vue'
import { Toast } from 'vant'
import { wxShareFn } from '@/utils/wxjdk.js'
import store from '@/store/index.js'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      name: '首页'
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue'),
    meta: {
      name: '登录'
    }
  },
  {
    path: '/personal',
    name: 'personal',
    component: () => import('../views/Personal.vue'),
    meta: {
      name: '个人中心'
    },
    beforeEnter: (to, from, next) => {
      const token = (sessionStorage.getItem('token') || to.query.token) || null
      if (token) {
        next()
      } else {
        Toast('请先登录!')
        if (from.path != '/login') {
          router.push({ path: '/login' })
        }
      }
    }
  },
  {
    path: '/mylist',
    name: 'mylist',
    component: () => import('../views/Mylist.vue'),
    meta: {
      name: '活动列表'
    },
    beforeEnter: (to, from, next) => {
      const token = sessionStorage.getItem('token') || null
      if (token) {
        next()
      } else {
        Toast('请先登录!')
        if (from.path != '/login') {
          router.push({ path: '/login' })
        }
      }
    }
  },
  {
    path: '/module',
    name: 'module',
    component: () => import('../views/Module.vue'),
    meta: {
      name: '模板详情'
    }
  },
  {
    path: '/produce',
    name: 'produce',
    component: () => import('../views/Produce.vue'),
    meta: {
      name: '产品与服务'
    }
  },
  {
    path: '/aboutMe',
    name: 'aboutMe',
    component: () => import('../views/AboutMe.vue'),
    meta: {
      name: '关于我们'
    }
  },
  {
    path: '/help',
    name: 'help',
    component: () => import('../views/Help.vue'),
    meta: {
      name: '帮助中心'
    },
    redirect: '/help/helpindex',
    children: [
      {
        path: 'helpindex',
        name: 'helpindex',
        component: () => import('../views/help/HelpIndex.vue'),
        meta: {
          name: '帮助中心'
        }
      },
      {
        path: 'helpinfo',
        name: 'helpinfo',
        component: () => import('../views/help/HelpInfo.vue'),
        meta: {
          name: '帮助中心'
        }
      },
      {
        path: 'helptype',
        name: 'helptype',
        component: () => import('../views/help/HelpType.vue'),
        meta: {
          name: '帮助中心'
        }
      },
      {
        path: 'protocol',
        name: 'protocol',
        component: () => import('../views/help/Protocol.vue'),
        meta: {
          name: '用户登录注册协议'
        }
      }
    ]
  },
  {
    path: '/square',
    name: 'square',
    component: () => import('../views/Square.vue'),
    meta: {
      name: '活动广场'
    }
  },
  {
    path: '/templetaCenter',
    name: 'templetaCenter',
    component: () => import('../views/TempletaCenter.vue'),
    meta: {
      name: '模板中心'
    }
  },
  {
    path: '/actions',
    name: 'actions',
    component: () => import('../views/Actions.vue'),
    meta: {
      name: '解决方案'
    }
  }
]

const router = new VueRouter({
  routes
})
// 全局分享
router.beforeEach((to, from, next) => {
  document.title = to.meta.name + '-' + (sessionStorage.getItem('name') || '投票平台')
  if (store.state.browser) {
    setTimeout(() => {
      // wxShareFn(location.href, sessionStorage.getItem('syslogmain'), to.meta.name)
      wxShareFn(location.href, sessionStorage.getItem('sysico'), to.meta.name)
    }, 500)
  }
  next()
})
// 全局分享
router.afterEach((to, from) => {
  document.title = to.meta.name + '-' + (sessionStorage.getItem('name') || '投票平台')
})

export default router
