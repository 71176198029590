// WXJDK
import wx from 'weixin-js-sdk'
import { wxShare } from '@/api/api.js'

export const wxShareFn = (url, img, text) => {
  wxShare({
    url,
    apis: 'updateAppMessageShareData,updateTimelineShareData'
  }).then(res => {
    wx.config({
      debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
      appId: res.appId, // 必填，公众号的唯一标识
      timestamp: res.timestamp, // 必填，生成签名的时间戳
      nonceStr: res.nonceStr, // 必填，生成签名的随机串
      signature: res.signature, // 必填，签名
      jsApiList: res.jsApiList // 必填，需要使用的JS接口列表
    })
    wx.ready(function () { // 需在用户可能点击分享按钮前就先调用
      wx.updateAppMessageShareData({
        title: sessionStorage.getItem('name') || '投票平台', // 分享标题
        desc: (sessionStorage.getItem('name') || '投票平台') + '-' + text, // 分享描述
        link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: img, // 分享图标
        success: function () {
          // 设置成功
        }
      })
      wx.updateTimelineShareData({
        title: sessionStorage.getItem('name') || '投票平台', // 分享标题
        link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: img, // 分享图标
        success: function () {
          // 设置成功
        }
      })
    })
  })
}
