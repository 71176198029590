import store from '@/store/index.js'
// 判断浏览器类型：Android/iOS
function getOSType () {
  if (/(Android)/i.test(navigator.userAgent)) {
    return 'Android'
  } else if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
    return 'IOS'
  }
}
// 判断是否是微信浏览器
function isWeixin () {
  if (/(micromessenger)/i.test(navigator.userAgent)) {
    return true
  } else {
    return false
  }
}

store.commit('setBrowser', isWeixin())
store.commit('setOSType', getOSType())
