exports.install = function (Vue, options) {
  Vue.prototype.toastText = function (text) {
    this.$toast({
      message: text
    })
  }
  Vue.prototype.toastSucc = function (text) {
    this.$toast.success({
      message: text
    })
  }
  Vue.prototype.toastFail = function (text) {
    this.$toast.fail({
      message: text
    })
  }
  Vue.prototype.toastLoading = function (text) {
    this.$toast.loading({
      mask: true,
      message: text,
      duration: 0
    })
  }
  Vue.prototype.toastClose = function () {
    this.$toast.clear({})
  }
}
